/* eslint-disable */
import { Suspense, useCallback, useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Autocomplete, Box, Breadcrumbs, Button, Container, Grid, Link, Popover, TextField, Typography } from "@material-ui/core";
import { DeviceV2, Events, Log, WipeReport } from "src/Models/models";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import DeviceDetail from "../../components/dashboard/devices/DeviceDetail";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PencilAltIcon from "../../icons/PencilAlt";
import PrintIcon from '@mui/icons-material/Print';
import LinkIcon from '@mui/icons-material/Link';
import DeviceActions from "../../components/dashboard/devices/DeviceActions";
import DeviceHistory from "../../components/dashboard/devices/DeviceHistory";
import { getDeviceByTechtag, getEventByTechTag, getWipeReportByTechtag } from "src/dataservies/deviceDataservice";
import { useDymoCheckService, useDymoFetchPrinters, dymoRequestBuilder, useDymoOpenLabel } from "react-dymo-hooks";
import { specLabel, monitorLabel,mobileLabel, otherLabel, barcodeLabel } from "src/asset/label";
import useAuth from '../../hooks/useAuth';
import LoadingScreen from "src/components/LoadingScreen";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useStorageDownloadURL } from "reactfire";
import firebase from "firebase";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';


const DeviceDetails: FC = () => {
  const [labelSource, setLabelSource] = useState<any>(null);
  const [isSpecLabel, setisSpecLabel] = useState<boolean>(true);
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const statusDymoService = useDymoCheckService();
  const { printers } = useDymoFetchPrinters(statusDymoService);
  const [selectedPrinter, setSelectedPrinter] = useState<string>("");
  const [device, setDevice] = useState<DeviceV2 | null>(null);
  const [wipeReports,setWipeReports] = useState<WipeReport[] | null>([]);
  const [events, setEvents] = useState<Events>(null);
  const { techTag } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const spaceLabelType = (device : DeviceV2) => {
    if (device.deviceType === "desktop" || device.deviceType === "laptop"){
      return specLabel(
        device.techTag,
        device["BasicComponent.CpuTitle"],
        device["BasicComponent.RamTitle"],
        device["BasicComponent.StorageAmount"],
        device["SystemInfo.Serial"],
        device["SystemInfo.Make"],
        device["SystemInfo.Model"],
        device.ownerLogo.profilepic_base64,
      )
    }else if (device.deviceType === "monitor"){
      return monitorLabel(
        device.techTag,
        device["MonitorInfo.Model"],
        device["MonitorInfo.MonitorID"],
        device["MonitorInfo.Size"],
        device["MonitorInfo.Serial"],
        device.ownerLogo.profilepic_base64,
      )
    }else if (device.deviceType === "other"){
      return otherLabel(
        device.techTag,
        device["SystemInfo.Make"],
        device["SystemInfo.Model"],
        device["SystemInfo.Serial"],
        device.ownerLogo.profilepic_base64,
      )
    }else if (device.deviceType === "mobile"){
      return mobileLabel(
        device.techTag,
        device["SystemInfo.Make"],
        device["SystemInfo.Model"],
        device["BasicComponent.StorageAmount"],
        device["SystemInfo.Carrier"],
        device["SystemInfo.Serial"],
        device.ownerLogo.profilepic_base64,
      )
    }
  }



  const { label: barcodeLabelData } = useDymoOpenLabel(
    statusDymoService,
    device
      ? barcodeLabel(device?.techTag.toString(), device?.lotNumber?.toString() ?? '')
      : null
  );
  const { label: specLabelData } = useDymoOpenLabel(
    statusDymoService,
    device
      ? spaceLabelType(device)
      : null
  );


  const handleSelected = (v) => {
    setSelectedPrinter(v.target.textContent);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setisSpecLabel(true);
    setLabelSource(specLabelData);
    setAnchorEl(event.currentTarget);
  };


  
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setisSpecLabel(false);
   setLabelSource(barcodeLabelData);
    setAnchorEl(event.currentTarget);
  };

  const Labelparams = () =>  {

    const labelType = isSpecLabel ? spaceLabelType(device) :  barcodeLabel(device?.techTag?.toString(), device?.lotNumber?.toString() ?? '')

    return {
      data: `printerName=${encodeURIComponent(selectedPrinter)}&printParamsXml=&labelXml=${encodeURIComponent(labelType)}&labelSetXml=`,
    }
  }


  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const print = () => {
    dymoRequestBuilder({
      method: "POST",
      wsAction: "printLabel",
      axiosOtherParams: Labelparams(),
    });
  };
  const getDevice = useCallback(async () => {
    try {
      const result = await getDeviceByTechtag(techTag);
      const wipe = await getWipeReportByTechtag(techTag);
      const events = await getEventByTechTag(techTag);
      if (isMountedRef.current) {
        setDevice(result);
        setWipeReports(wipe);
        setEvents(events);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  if (!device) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Device Details
              </Typography>
              {device && <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/app" variant="subtitle2">
                  Dashboard
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/app/${device.deviceType}`} variant="subtitle2">
                  {device.deviceType}
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {techTag}
                </Typography>
              </Breadcrumbs>}
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {device.deviceFingerPrintHash && <FetchFilePathBtn storagePath={`devices/${techTag}/deviceFingerPrint/deviceFingerPrint_${device.techTag}_originstamp.txt`} btnName="Download Device Fingerprint"/>}
              <Button startIcon={<DocumentScannerIcon fontSize="small" />} color="primary" sx={{ m: 1 }} variant="contained" target='_blank' href={`https://portal.techreuse.com/public/${techTag}`}>
              Digital Passport
                </Button>
                 <Button startIcon={<PrintIcon fontSize="small" />} color="primary" sx={{ m: 1 }} variant="contained" onClick={handleClick2}>
                  Print Tech Tag
                </Button>
                <Button startIcon={<PrintIcon fontSize="small" />} color="primary" sx={{ m: 1 }} variant="contained" onClick={handleClick}>
                  Print Spec Label
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <img style={{ marginTop: "1em", marginLeft: "1em" }} src={"data:image/png;base64," + labelSource} alt="dymo label preview" />;
                  <Box sx={{ pt: 0.5, pb: 0.5 }} width="500px">
                    <Autocomplete
                      fullWidth
                      onInputChange={handleSelected}
                      getOptionLabel={(option: any) => option.name}
                      options={printers}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label="Select Printer"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                    <Button color="primary" sx={{ m: 1 }} variant="contained" onClick={print}>
                      Print
                    </Button>
                  </Box>
                </Popover>
                <Button
                  color="primary"
                  startIcon={<PencilAltIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  component={RouterLink}
                  to={`/app/computers/${techTag}/edit`}
                  variant="contained"
                >
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item md={5} xl={5} xs={12}>
              <Suspense fallback={<LoadingScreen />}>
                <DeviceDetail device={device} />
                </Suspense>
              </Grid>
              <Grid item md={7} xl={7} xs={12}>
                <DeviceActions onChange={() => {}} edit={false} device={device} HddWipeReport={wipeReports} deviceType={device.deviceType} />
                {events && <DeviceHistory events={events} />}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DeviceDetails;

export const FetchFilePathBtn = ({ storagePath, btnName }) => {

  const { data: imageURL } = useStorageDownloadURL(firebase.storage().ref(storagePath));
  return <Button color="primary" sx={{ m: 1 }} variant="contained" download target='_blank' href={imageURL}>
 {btnName}
</Button>
};

